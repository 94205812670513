import React from "react";
import styles from "./index.module.scss";
import data from "@utilities/data/data.js";

const Partners = () => {
  const { partners } = data;
  return (
    <section id="sponsors" className={styles.partners}>
      <h2>Partners</h2>
      <div className={styles.partnersInner}>
        {partners &&
          partners.map((item, index) => {
            const { title, logo, url } = item;
            return (
              <div key={index} className={styles.partnersItem}>
                {logo ? (
                  <>
                    {url ? (
                      <a href={url} target="_blank">
                        <img draggable={false} title={title} src={logo} />
                      </a>
                    ) : (
                      <img draggable={false} title={title} src={logo} />
                    )}
                  </>
                ) : (
                  <h3>{title}</h3>
                )}
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default Partners;
