import React from "react";
import styles from "./index.module.scss";

const Performances = () => {
  return (
    <section id="shows" className={styles.performances}>
      <h2>Shows</h2>
      <div className={styles.performancesInner}>
        <h3>
          Hey, we're over here now
          <br />
          <a href="https://www.digitalpridefest.com">Digital PrideFest</a>
        </h3>
      </div>
    </section>
  );
};

export default Performances;
